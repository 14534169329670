@tailwind base;
@tailwind components;
@tailwind utilities;

code {
    background: #ffe6f1;
    color: #e83e8c;
    padding: 0.2rem 0.3rem;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
}

.highlight{
    background-image: linear-gradient(145deg, rgba(255, 255, 255, 0), #FFFF25 85%, rgba(255, 255, 255, 0));
}

.profile-main-loader{
    top: 50%;
    left: 50%;
    position: relative;
    width: 45px;
}

.profile-main-loader .loader {
    position: absolute !important;
    margin: 0 auto;
    width: 35px;
    height: 35px;
}
.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.loader-path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    strokeLinecap: round;
}

.tableheader-dropdown {
    cursor: pointer;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@-webkit-keyframes color {
    0% {
        stroke: #5850ec;
    }
    40% {
        stroke: #5850ec;
    }
    66% {
        stroke: #5850ec;
    }
    80%, 90% {
        stroke: #5850ec;
    }
}
@keyframes color {
    0% {
        stroke: #5850ec;
    }
    40% {
        stroke: #5850ec;
    }
    66% {
        stroke: #5850ec;
    }
    80%, 90% {
        stroke: #5850ec;
    }
}
